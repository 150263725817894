import React from 'react';
import Layout from '../../components/Layout';
// import blogd1 from '../../assets/images/2022/medical-outreach/fakenews.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import { Link } from 'gatsby';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                {/* <img src={blogd1} alt='' /> */}
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  RE: Irredeemable Country
                </h2>
                {/* <h3>FRAUD ALERT</h3> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The attention of the Lagos University Teaching Hospital (LUTH)
                  management has been drawn to a viral news item on social media
                  titled "Irredeemable Country" While the management is
                  investigating the matter as the issue was first brought to our
                  attention through the said publication, it is important to
                  respond to the issue concerning <strong>"Autopsy"</strong>{' '}
                  mentioned in the publication.
                  <br />
                  Coroner Law is a Lagos State Law, and the Law mandates that an
                  autopsy should be carried out on a patient who dies within 24
                  hours of admission to ascertain the true cause of death.
                  Thereafter, a death certificate will be issued. In many
                  instances, patient's relations do refuse autopsy, as has
                  happened in this case. In such circumstances, the hospital has
                  a mechanism for resolution that involves our legal officer
                  explaining the implications to the patient's relations, and
                  this sometimes causes delay in releasing the body to the
                  family.
                  <br />
                  It should also be stated that it is hospital policy to
                  transfer the dead from the wards to the morgue for proper
                  documentation before they are released to the family. The
                  management is poised to investigate the circumstances of this
                  case, but we are of the opinion that the writer who by his/her
                  own admission claimed the management of the hospital was not
                  aware, should have contacted the Head of Department of
                  Anatomic and Molecular Pathology in charge of Mortuary Sevices
                  or the Chairman Medical Advisory Committee (CMAC) or Servicom,
                  rather than resorting to social media.
                  <br />
                  As a responsible institution, we will contact the writer and
                  the family of the deceased to get full details of what
                  transpired.
                </p>
                <p>
                  Thank you.
                  <br />
                  <strong>Dr. Ayodeji Oluwole</strong>
                  <br />
                  <strong>Chairman Medical Advisory Committee, LUTH</strong>
                  <br />
                </p>
              </div>
            </div>
            <div className='share-block'>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Fake News  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
